import React from 'react';
import PropTypes from 'prop-types';
import SEO from '../components/seo';
import Layout from '../components/layout';
import StrapiComponentRender from '../components/strapiComponentRender';

const StandardSite = props => {
  return (
    <Layout>
      <SEO
        title={props.pathContext.seo.title}
        description={props.pathContext.seo.description}
        keywords={props.pathContext.seo.keywords}
      />
      {/*<h1>{props.pathContext.pageTitle}</h1>*/}
    {/*  TB*/}
      {props.pathContext.content && props.pathContext.content.map(contentItem => (
        <StrapiComponentRender content={contentItem} />
      ))}
    {/*TE*/}
    </Layout>
  );
};

StandardSite.propTypes = {

};

export default StandardSite;
